/* eslint-disable jsx-a11y/anchor-is-valid */
import { Col, Row } from "antd";
import useScrollPosition from "../../hooks/useScrollPosition";
import styles from "./index.module.scss";
import classNames from "classnames";
import { use100vh } from "../../hooks/use100vh";
import { BarChart, Box, Database, FileText, Gem, HandThumbsUp, Palette, PatchCheck, People, Phone } from "react-bootstrap-icons";
import { useLocation, useNavigate } from "react-router";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import emailjs from "@emailjs/browser";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import Stafflow from "../../assets/stafflow-logo.svg";

export default function HomeView() {
  const scrollPosition = useScrollPosition();

  useEffect(() => emailjs.init("6i9htOJiem6rEGLqo"), []);

  const nav = useNavigate();

  const size = use100vh();

  const location = useLocation();

  const applyScroll = async () => {
    const params = new URLSearchParams(location.search);
    const tagId = params.get("tagId");
    console.log(tagId);
    if (tagId === "contacts") {
      handleClickScroll("Contacts");

      params.delete("tagId");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      applyScroll();
    }, 500);
  }, []);

  const windowSize = useWindowDimensions();

  const whatWeDo = [
    {
      icon: <Gem />,
      color: "rgb(23, 146, 229)",
      title: "Инновации и качество",
      body: "Мы постоянно исследуем новые технологии и методологии, чтобы наши решения не только отвечали современным требованиям, но и были готовы к будущим вызовам.",
    },
    {
      icon: <Box />,
      color: "rgb(223, 82, 114)",
      title: "Скорость и гибкость",
      body: "В мире, где время — это ценность, мы гарантируем высокую скорость реализации проектов без потери качества, адаптируясь к изменениям и нуждам клиентов.",
    },
    {
      icon: <People />,
      color: "rgb(102, 67, 164)",
      title: "Партнерство и доверие",
      body: "Мы видим в каждом клиенте партнера и стремимся к долгосрочным отношениям, основанным на взаимном доверии и уважении.",
    },
    {
      icon: <PatchCheck />,
      color: "rgb(63, 162, 161)",
      title: "Профессионализм и мастерство",
      body: "Наша команда состоит из высококвалифицированных специалистов, чье мастерство и опыт являются залогом успеха наших проектов.",
    },
  ];

  const services = [
    {
      icon: <img src={Stafflow} style={{ height: 40 }} alt="https://stafflow.by/" />,
      color: "#6CB9F0",
      title: "Разработка собственного продукта",
      body: "Stafflow от CatLogic – это инновационная HRMS платформа, предназначенная для комплексного управления персоналом. Stafflow автоматизирует процессы найма, обучения и оценки компетенций сотрудников, обеспечивая эффективное взаимодействие между HR и руководством, а также поддерживая развитие и удовлетворенность сотрудников.",
      link: "https://stafflow.by/",
    },
    {
      icon: <FileText />,
      color: "#6CB9F0",
      title: "Проектирование и анализ",
      body: "Прежде чем приступить к разработке, наша команда проводит тщательное проектирование и анализ, чтобы гарантировать, что конечный продукт полностью соответствует потребностям заказчика. Мы используем современные методики и инструменты для создания четких и эффективных проектных решений.",
    },
    {
      icon: <BarChart />,
      color: "#6CB9F0",
      title: "Исследования рынка",
      body: "Для успешного запуска продукта на рынок критически важно понимать потребности целевой аудитории. Мы проводим глубокие исследования рынка, анализируя тренды и предпочтения потребителей, чтобы наши решения были актуальны и востребованы.",
    },
    {
      icon: <Palette />,
      color: "#6CB9F0",
      title: "Дизайн",
      body: "Дизайн играет ключевую роль в создании привлекательного и интуитивно понятного пользовательского интерфейса. Команда UX/UI дизайнеров заботится о том, чтобы каждый элемент продукта был не только красивым, но и максимально функциональным.",
    },
    {
      icon: <HandThumbsUp />,
      color: "#6CB9F0",
      title: "Разработка под заказ",
      body: "Мы предлагаем услуги разработки программного обеспечения под индивидуальные нужды клиентов. Это включает веб-платформы, мобильные приложения, а также сложные интегрированные системы. Наш подход гарантирует, что каждый проект реализуется с учетом специфики бизнеса заказчика, его целей и задач.",
    },
    {
      icon: <Database />,
      color: "#6CB9F0",
      title: "Развертывание ПО в облачных сервисах и у заказчиков",
      body: "Мы обладаем глубокими знаниями в развертывании программного обеспечения как в облачных сервисах, так и непосредственно у заказчиков. Это позволяет нам обеспечивать высокую доступность и безопасность решений на всех этапах их эксплуатации.",
    },
    {
      icon: <Phone />,
      color: "#6CB9F0",
      title: "Создание мобильных приложений",
      body: "Наши специалисты разрабатывают как нативные, так и кросс-платформенные мобильные приложения, которые отличаются высоким качеством, производительностью и пользовательским опытом. Мы используем последние технологии и платформы для создания приложений, которые помогают нашим клиентам быть на шаг впереди конкурентов.",
    },
  ];

  // const news = [
  //   {
  //     title: 'Тренды в HR-технологиях 2024 года',
  //     body: 'Обзор последних инноваций и тенденций в области HR-технологий и их потенциальное влияние на управление персоналом.',
  //   },
  //   {
  //     title: 'Интервью с CEO CatLogic',
  //     body: 'Инсайты от лидера с 30-летним опытом работы – от компьютерной техники до HR-технологий.',
  //   },
  //   {
  //     title: 'Как удаленная работа меняет HR-практики',
  //     body: 'Анализ изменений в наборе и удержании талантов в условиях глобального перехода на удаленную работу.',
  //   },
  // ];

  const isMobile = windowSize.width < 500;

  const handleClickScroll = (id: string) => {
    const element = document.getElementById(id)?.offsetTop;
    if (element) {
      let size = -30;
      if (id === "Contacts") {
        if (isMobile) {
          size += 350;
        }
      }
      window.scrollTo({ top: element + size, behavior: "smooth" });
      // 👇 Will scroll smoothly to the top of the next section
      // element.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <main className={styles.main}>
      <Helmet>
        <title>CatLogic</title>
        <link rel="canonical" href="https://www.catlogic.tech" />
      </Helmet>
      <nav
        className={classNames(
          scrollPosition > size.initialHeight! ? styles.navbarDefault : scrollPosition > 50 ? styles.navbarScrolled : styles.navbar
        )}
      >
        <div className={styles.navContent}>
          <div className={styles.logo} onClick={scrollToTop}>
            <div className={styles.logoWhite} />
            <div className={styles.logoColor} />
          </div>
          <div className={styles.tabs}>
            <a hidden={isMobile} onClick={() => nav("/about")}>
              О нас
            </a>
            <a hidden={isMobile} onClick={() => handleClickScroll("Services")}>
              Услуги
            </a>
            <a hidden={isMobile} onClick={() => handleClickScroll("Contacts")}>
              Контакты
            </a>
            {/* <a hidden={isMobile} onClick={() => handleClickScroll('News')}>
              Блог
            </a> */}
            <a hidden={isMobile} onClick={() => nav("/career")}>
              Карьера
            </a>
            <div className={styles.headerButton} onClick={() => handleClickScroll("Contacts")}>
              Связаться
            </div>
          </div>
        </div>
      </nav>
      <div className={styles.content}>
        <div className={styles.info}>
          <div className={styles.label}>Мы catlogic.tech</div>
          <div className={styles.slogan}>Исключительное усиление</div>
          <div className={styles.slogan}>человеческих способностей</div>
          <div className={styles.slogan}>с десятикратным интеллектом</div>
          <div style={{ height: 32 }} />
          <div className={styles.topButton} onClick={() => handleClickScroll("Contacts")}>
            Связаться
          </div>
        </div>
        <video loop autoPlay muted playsInline>
          <source src="https://catlogic-spaces.fra1.cdn.digitaloceanspaces.com/system/3129977-uhd_3840_2160_30fps.mp4" />
        </video>
      </div>
      <div className={styles.area}>
        <div id="What_we_do" className={styles.whatWeDoWrap}>
          <div className={styles.whatWeDoBack} />
          <div className={styles.whatDo}>
            <h1>Миссия CatLogic</h1>
            <p>
              В CatLogic наша миссия заключается в том, чтобы использовать силу технологий для трансформации идей в инновационные
              ИТ-решения, которые способствуют росту и успеху наших клиентов. Мы стремимся к тому, чтобы каждый проект не просто достигал
              поставленных целей, но и превосходил ожидания, обеспечивая нашим клиентам конкурентное преимущество в их отраслях.
            </p>
            <Row gutter={[24, 24]}>
              {whatWeDo.map((e) => (
                <Col span={isMobile ? 24 : 12} key={e.title}>
                  <div className={styles.card}>
                    <div className={styles.icon} style={{ color: e.color }}>
                      {e.icon}
                    </div>
                    <h2>{e.title}</h2>
                    <p>{e.body}</p>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </div>
        <div id="Future" className={styles.future}>
          <div className={styles.futureBlur}>
            <div className={styles.futureContent}>
              <h1>На пути к будущему</h1>
              <p>
                В CatLogic мы верим в бесконечный потенциал технологий изменить мир к лучшему. Мы продолжаем исследовать новые горизонты,
                разрабатывая продукты, которые не только отвечают текущим требованиям рынка, но и предвосхищают будущие тренды.
                Присоединяйтесь к нам в этом путешествии, и давайте вместе создадим завтрашний день.
              </p>
            </div>
          </div>
        </div>
        <div id="Services" className={styles.serviceWrap}>
          <div className={styles.serviceBack} />
          <div className={styles.services}>
            <h1>Услуги и проекты </h1>
            <p>
              В <strong>CatLogic</strong> мы стремимся к созданию инновационных решений, которые помогают нашим клиентам достигать их
              бизнес-целей. Наша команда специалистов предлагает широкий спектр услуг, от идеи до запуска и поддержки.
            </p>
            <Row gutter={[24, 24]}>
              {services.map((e) => (
                <Col span={isMobile ? 24 : 12} key={e.title}>
                  <div
                    className={e.link ? styles.cardHoverable : styles.card}
                    onClick={() => {
                      if (e.link) {
                        window.open(e.link, "_blank");
                      }
                    }}
                  >
                    <div className={styles.icon} style={{ color: e.color }}>
                      {e.icon}
                    </div>
                    <h2>{e.title}</h2>
                    <p>{e.body}</p>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </div>
        <div id="News">
          <div className={styles.newsWrap}>
            {/* <div className={styles.news}>
            <h1>Новости</h1>
            <p>Читайте последние истории из нашего мира.</p>
            <Row gutter={[24, 24]}>
              {news.map((e) => (
                <Col span={isMobile ? 24 : 8}>
                  <div className={styles.article}>
                    <h2>{e.title}</h2>
                    <p>{e.body}</p>
                  </div>
                </Col>
              ))}
            </Row>
          </div> */}

            <div id="Contacts" className={styles.contacts}>
              <h1>Контакты</h1>
              <Row gutter={48}>
                <Col span={isMobile ? 24 : 14}>
                  <p>
                    В CatLogic мы ценим открытость и доступность для наших клиентов и партнёров. Наша команда работает полностью удалённо,
                    что позволяет нам быть гибкими и оперативно реагировать на запросы со всего мира.
                  </p>
                  <p>Если у вас возникли вопросы, предложения или вы хотите начать с нами сотрудничество, мы всегда рады общению.</p>
                  <div className={styles.contactLogo} />
                </Col>
                <Col span={isMobile ? 24 : 10}>
                  <BitrixForm />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>

      <footer id="Footer" className={styles.footer}>
        <div className={styles.footerColor}>
          <div className={styles.footerContent}>
            <Row gutter={[24, 48]}>
              <Col span={windowSize.width > 500 ? 4 : 24} style={{ display: "flex", justifyContent: "center" }}>
                <div className={styles.cat} />
              </Col>
              <Col span={windowSize.width > 500 ? 4 : 24} style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
                <h6>Компания</h6>
                <Link to="/about">О нас</Link>
                <Link to="/career">Карьера</Link>
              </Col>
              <Col span={windowSize.width > 500 ? 4 : 24} style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
                <h6>Услуги</h6>
                <a onClick={() => handleClickScroll("Services")}>Разработка</a>
              </Col>
              <Col span={windowSize.width > 500 ? 7 : 24} style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
                <h6>Документы</h6>
                <Link to="/privacy-policy">Политика конфиденциальности</Link>
                <Link to="/cookies-policy">Политика использования файлов cookie</Link>
              </Col>
              <Col span={windowSize.width > 500 ? 4 : 24} style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
                <h6>Контакты</h6>
                <a href="mailto:info@catlogic.tech&body=привет?subject=вопрос">info@catlogic.tech</a>
                {/* <a href='tel:+375 29 103 2323'>+375 29 103 2323</a> */}
              </Col>
            </Row>
          </div>
        </div>
      </footer>
    </main>
  );
}

const BitrixForm: React.FC = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn-ru.bitrix24.by/b28691664/crm/form/loader_6.js?" + ((Date.now() / 180000) | 0);
    script.async = true;
    script.setAttribute("data-b24-form", "inline/6/457hyu");
    script.setAttribute("data-skip-moving", "true");

    const scriptContainer = document.getElementById("bitrix-form-container");
    if (scriptContainer) {
      scriptContainer.appendChild(script);
    }

    return () => {
      if (scriptContainer) {
        scriptContainer.removeChild(script);
      }
    };
  }, []);

  return <div className={styles.form} id="bitrix-form-container"></div>;
};
